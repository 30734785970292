const initMarquee = marquee => {
  const row = marquee.querySelector('.marquee-row')
  const numClones = Math.ceil(innerWidth / row.offsetWidth)

  for (let i = 0; i < numClones; i++) {
    const clone = row.cloneNode(true)
    clone.setAttribute('aria-hidden', true)
    row.parentNode.appendChild(clone)
  }

  marquee.animate([
    { transform: 'none' },
    { transform: `translateX(-${row.offsetWidth}px)` },
  ], {
    duration: row.offsetWidth * 15,
    iterations: Infinity,
  })
}

const init = () => document.querySelectorAll('.marquee').forEach(initMarquee)

document.addEventListener('DOMContentLoaded', init)
