const handleIntersection = entries =>
  entries.forEach(entry =>
    entry.isIntersecting
    ? entry.target.nextElementSibling?.removeAttribute('stuck')
    : entry.target.nextElementSibling?.setAttribute('stuck', '')
  )

const init = () => {
  // Skip browsers that don't support position: sticky
  const element = document.createElement('div')
  element.style.position = 'sticky'
  if (!element.style.position) return

  const observer = new IntersectionObserver(handleIntersection)
  document.querySelectorAll('.sticky-measure').forEach(e => observer.observe(e))
}

document.addEventListener('DOMContentLoaded', init)
